var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cw-page',{staticClass:"continuous-loan__complete",attrs:{"icon":"check"},scopedSlots:_vm._u([{key:"hero-title",fn:function(){return [_c('translate',[_vm._v(" Thank you for choosing our service! ")])]},proxy:true},{key:"actions",fn:function(){return [_c('v-spacer'),(_vm.product.ownPagesUrl)?_c('v-btn',{attrs:{"id":"submit","loading":_vm.$wait.waiting('SUBMIT_FORM'),"dark":!_vm.$wait.waiting('SUBMIT_FORM'),"color":"purple-one darken-2"},on:{"click":function($event){_vm.toMySaldo();
        _vm.$eventLogger.clickEvent($event);}}},[_c('translate',[_vm._v("Proceed to MySaldo")])],1):_vm._e()]},proxy:true}])},[[(_vm.loadingData)?_c('cw-form-loader',{attrs:{"animate":false}}):[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"mb-6"},[_c('translate',[_vm._v(" Thank you for choosing our service and welcome to Saldo! ")])],1),_c('p',{directives:[{name:"translate",rawName:"v-translate",value:({
              limit: _vm.$options.filters.currency(_vm.offer.loanLimit, { locale: _vm.locale }),
              openingTag: '<b>',
              closingTag: '</b>',
            }),expression:"{\n              limit: $options.filters.currency(offer.loanLimit, { locale }),\n              openingTag: '<b>',\n              closingTag: '</b>',\n            }"}],attrs:{"id":"continuous-loan__complete__loan-limit","render-html":"true"}},[_vm._v(" Your credit limit %{ openingTag }%{ limit }%{ closingTag } is available 24 hours a day, 7 days a week. ")]),(_vm.isNightWithdrawal)?_c('p',{directives:[{name:"translate",rawName:"v-translate",value:({
              amount: _vm.$options.filters.currency(_vm.initialData.withdrawalAmount, { locale: _vm.locale }),
              formattedNightWithdrawalTime: _vm.formattedNightWithdrawalTime,
              openingTag: '<b>',
              closingTag: '</b>',
            }),expression:"{\n              amount: $options.filters.currency(initialData.withdrawalAmount, { locale }),\n              formattedNightWithdrawalTime,\n              openingTag: '<b>',\n              closingTag: '</b>',\n            }"}],attrs:{"id":"continuous-loan__complete__withdrawal-amount","render-html":"true"}},[_vm._v(" First %{ openingTag }%{ amount }%{ closingTag } Withdrawal will be paid to the account you specified %{ openingTag } at the earliest %{ formattedNightWithdrawalTime }%{ closingTag } o'clock. ")]):_c('p',{directives:[{name:"translate",rawName:"v-translate",value:({
              amount: _vm.$options.filters.currency(_vm.initialData.withdrawalAmount, { locale: _vm.locale }),
              openingTag: '<b>',
              closingTag: '</b>',
            }),expression:"{\n              amount: $options.filters.currency(initialData.withdrawalAmount, { locale }),\n              openingTag: '<b>',\n              closingTag: '</b>',\n            }"}],attrs:{"id":"continuous-loan__complete__withdrawal-amount","render-html":"true"}},[_vm._v(" The first withdrawal of %{ openingTag }%{ amount }%{ closingTag } will be paid to the account specified in the loan application. ")])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('cw-review',{attrs:{"data":_vm.initialData}})],1)],1),_c('v-row',{staticClass:"continuous-loan__complete__cards fill-height grey lighten-3 py-4",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"continuous-loan__complete__card",attrs:{"cols":"12","sm":"6"}},[_c('v-card',{staticClass:"fill-height"},[_c('v-img',{staticClass:"white--text",attrs:{"src":require('@shared/assets/images/mysaldo.jpg'),"height":"200px"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}})],1)]},proxy:true}])}),_c('v-card-title',[_vm._v("MySaldo")]),_c('v-card-text',{staticClass:"text-xs-left"},[_c('p',{directives:[{name:"translate",rawName:"v-translate",value:({
                  openingTag: '<b>',
                  closingTag: '</b>',
                }),expression:"{\n                  openingTag: '<b>',\n                  closingTag: '</b>',\n                }"}],staticClass:"ma-0",attrs:{"render-html":"true"}},[_vm._v(" You can manage your loans easily at %{ openingTag }MySaldo%{ closingTag }. In MySaldo you can see your available additional services such as the Saldo Safe loan insurance that insures your payment ability in case of circumstances such as unemployment. ")])]),_c('v-card-actions',[_c('v-btn',{attrs:{"id":"redirect","color":"primary","text":""},on:{"click":function($event){_vm.toMySaldo();
                  _vm.$eventLogger.clickEvent($event);}}},[_vm._v(" MySaldo ")])],1)],1)],1)],1)]]],2)}
var staticRenderFns = []

export { render, staticRenderFns }