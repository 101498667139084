<template>
  <cw-page
    icon="check"
    class="continuous-loan__complete"
  >
    <template #hero-title>
      <translate>
        Thank you for choosing our service!
      </translate>
    </template>

    <template>
      <cw-form-loader
        v-if="loadingData"
        :animate="false"
      />

      <template v-else>
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <p class="mb-6">
              <translate>
                Thank you for choosing our service and welcome to Saldo!
              </translate>
            </p>
            <p
              id="continuous-loan__complete__loan-limit"
              v-translate="{
                limit: $options.filters.currency(offer.loanLimit, { locale }),
                openingTag: '<b>',
                closingTag: '</b>',
              }"
              render-html="true"
            >
              Your credit limit %{ openingTag }%{ limit }%{ closingTag } is available 24 hours
              a day, 7 days a week.
            </p>
            <p
              v-if="isNightWithdrawal"
              id="continuous-loan__complete__withdrawal-amount"
              v-translate="{
                amount: $options.filters.currency(initialData.withdrawalAmount, { locale }),
                formattedNightWithdrawalTime,
                openingTag: '<b>',
                closingTag: '</b>',
              }"
              render-html="true"
            >
              First %{ openingTag }%{ amount }%{ closingTag } Withdrawal will be paid to the
              account you specified %{ openingTag } at the earliest
              %{ formattedNightWithdrawalTime }%{ closingTag } o'clock.
            </p>
            <p
              v-else
              id="continuous-loan__complete__withdrawal-amount"
              v-translate="{
                amount: $options.filters.currency(initialData.withdrawalAmount, { locale }),
                openingTag: '<b>',
                closingTag: '</b>',
              }"
              render-html="true"
            >
              The first withdrawal of %{ openingTag }%{ amount }%{ closingTag } will be paid
              to the account specified in the loan application.
            </p>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <cw-review :data="initialData"/>
          </v-col>
        </v-row>

        <v-row
          justify="center"

          class="continuous-loan__complete__cards fill-height grey lighten-3 py-4"
        >
          <v-col
            cols="12"
            sm="6"
            class="continuous-loan__complete__card"
          >
            <v-card class="fill-height">
              <v-img
                :src="require('@shared/assets/images/mysaldo.jpg')"
                class="white--text"
                height="200px"
              >
                <template #placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      color="primary"
                      indeterminate
                    />
                  </v-row>
                </template>
              </v-img>

              <v-card-title>MySaldo</v-card-title>

              <v-card-text class="text-xs-left">
                <p
                  v-translate="{
                    openingTag: '<b>',
                    closingTag: '</b>',
                  }"
                  class="ma-0"
                  render-html="true"
                >
                  You can manage your loans easily at %{ openingTag }MySaldo%{ closingTag }.
                  In MySaldo you can see your available additional services such as the
                  Saldo Safe loan insurance that insures your payment ability in case of
                  circumstances such as unemployment.
                </p>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  id="redirect"
                  color="primary"
                  text
                  @click="
                    toMySaldo();
                    $eventLogger.clickEvent($event);
                  "
                >
                  MySaldo
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </template>

    <template #actions>
      <v-spacer/>
      <v-btn
        v-if="product.ownPagesUrl"
        id="submit"
        :loading="$wait.waiting('SUBMIT_FORM')"
        :dark="!$wait.waiting('SUBMIT_FORM')"
        color="purple-one darken-2"
        @click="
          toMySaldo();
          $eventLogger.clickEvent($event);
        "
      >
        <translate>Proceed to MySaldo</translate>
      </v-btn>
    </template>
  </cw-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CwCompleteContinuousLoan',

  components: {
    'cw-review': () => import('@shared/components/Review'),
  },

  props: {
    timeout: {
      type: Number,
      default: 10000,
    },
  },

  data: () => ({
    loadingData: false,
    redirect: null,
  }),

  computed: {
    ...mapGetters({
      initialData: 'application/getInitialData',
      locale: 'application/locale',
      offer: 'application/getSelectedOffer',
      product: 'application/getProduct',
    }),

    brand() {
      if (!this.product || !this.product.brand) return '';
      return this.product.brand.toLowerCase();
    },

    isNightWithdrawal() {
      const { nightWithdrawalTime } = this.initialData;

      if (nightWithdrawalTime) return true;

      return false;
    },

    formattedNightWithdrawalTime() {
      const { nightWithdrawalTime } = this.initialData;

      if (!nightWithdrawalTime) return '';

      return this.$filters.date(nightWithdrawalTime, {
        type: 'time',
        locale: this.locale,
      });
    },
  },

  mounted() {
    this.automaticRedirectToMySaldo();
  },

  beforeDestroy() {
    clearTimeout(this.redirect);
  },

  methods: {
    ...mapActions({
      transferSession: 'application/transferSession',
    }),

    automaticRedirectToMySaldo() {
      this.redirect = setTimeout(async () => {
        await this.toMySaldo();
      }, this.timeout);
    },

    async toMySaldo() {
      const ownPagesUrl = (this.product.ownPagesUrl || '').replace(/\/+$/, '');
      const { tokenId } = (await this.transferSession()).data;
      const url = tokenId !== undefined ? `${ownPagesUrl}/app/fromNetApp/${tokenId}` : ownPagesUrl;
      window.location = url;
    },
  },
};
</script>
